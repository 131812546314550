import { Box, Typography } from "@mui/material";
import config from "services/utils/app/reverse/config";

const Footer = () => {
  return (
    <Box padding={2} sx={{ display: "flex", justifyContent: "center", bgcolor: "background.default" }}>
      <Typography color="textPrimary">Version 1.0.0 ({config.PLAN_ID.slice(0, 8)})</Typography>
    </Box>
  );
};

export default Footer;
