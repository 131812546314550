import { Stdlib_User } from "@reach-sh/stdlib/dist/types/interfaces";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAccountAssets, getAccountInfo } from "services/api/algorand";
import { connectWallet } from "services/utils/connectWallet";
import { RootState } from "store";
import { ProviderType, WalletFallbackType, Theme, NetworkType } from "types";

export const fetchAccountInfo = createAsyncThunk(
  "app/fetchAccountInfo",
  async ({
    address,
    reach
  }: {
    address: string;
    reach: Stdlib_User<any, any, any, any, any, any, any, any, any, any>;
  }) => {
    if (!address) return {};

    const acc = await reach.connectAccount({ addr: address });
    const balance = (await reach.balanceOf(acc)).toNumber() / 10 ** 6;
    const accInfo = await getAccountInfo(acc.networkAccount.addr, { exclude: "all" });
    const accAssetInfo = await getAccountAssets(acc.networkAccount.addr);

    return {
      address,
      acc: {
        ...acc,
        ...(accInfo.account ?? {}),
        assets: accAssetInfo?.assets ?? []
      },
      balance
    };
  }
);

export const handleConnectWallet = createAsyncThunk(
  "app/connectWallet",
  async ({
    reach,
    walletFallback
  }: {
    reach: Stdlib_User<any, any, any, any, any, any, any, any, any, any>;
    walletFallback: WalletFallbackType;
  }) => {
    const accounts = await connectWallet(walletFallback);
    if (!accounts?.length) {
      return {};
    }
    const { address } = accounts[0];

    const acc = await reach.connectAccount({ addr: address });
    const accInfo = await getAccountInfo(acc.networkAccount.addr, { exclude: "all" });
    const balance = Number((accInfo.account?.amount ?? 0) / 10 ** 6); // balance for algorand
    const accAssetInfo = await getAccountAssets(acc.networkAccount.addr);

    return {
      address,
      acc: {
        ...acc,
        ...(accInfo.account ?? {})
      },
      assets: accAssetInfo?.assets ?? [],
      balance
    };
  }
);

interface AppState {
  walletFallback: WalletFallbackType;
  providerType: ProviderType;
  network: NetworkType;
  address?: string;
  theme: Theme;
  reachAccount: any; // Reach Stdlib account
  isConnecting: boolean;
  balance: number;
}

const initialState: AppState = {
  walletFallback: "MyAlgoConnect",
  providerType: "TestNet",
  network: "Algorand",
  theme: "light",
  reachAccount: null,
  isConnecting: false,
  balance: 0
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setWalletFallback: (state, action: PayloadAction<WalletFallbackType>) => {
      state.walletFallback = action.payload;
    },
    setProviderType: (state, action: PayloadAction<ProviderType>) => {
      // Update local storage
      localStorage.setItem("providerEnv", action.payload);
      state.providerType = action.payload;
    },
    setAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
    },
    setTheme: (state, action: PayloadAction<Theme>) => {
      state.theme = action.payload;
    },
    toggleTheme: (state) => {
      state.theme = state.theme === "light" ? "dark" : "light";
    },
    setNetwork: (state, action: PayloadAction<NetworkType>) => {
      state.network = action.payload;
    },
    setReachAccount: (state, action: PayloadAction<any>) => {
      state.reachAccount = action.payload;
    },
    resetAccount: (state) => {
      state.reachAccount = null;
      state.address = "";
      state.balance = 0;
      state.isConnecting = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(handleConnectWallet.pending, (state, action) => {
      state.isConnecting = true;
    });
    builder.addCase(handleConnectWallet.fulfilled, (state, action) => {
      state.isConnecting = false;
      state.address = action.payload.address;
      state.reachAccount = action.payload.acc;
      state.balance = action.payload.balance ?? 0;
    });
    builder.addCase(handleConnectWallet.rejected, (state, action) => {
      state.isConnecting = false;
    });
    builder.addCase(fetchAccountInfo.pending, (state, action) => {
      state.isConnecting = true;
    });
    builder.addCase(fetchAccountInfo.fulfilled, (state, action) => {
      state.isConnecting = false;
      state.address = action.payload.address;
      state.reachAccount = action.payload.acc;
      state.balance = action.payload.balance ?? 0;
    });
    builder.addCase(fetchAccountInfo.rejected, (state, action) => {
      state.isConnecting = false;
    });
  }
});

export const { setWalletFallback, setProviderType, setAddress, setTheme, toggleTheme, setNetwork, resetAccount } =
  appSlice.actions;

export const selectWalletFallback = (state: RootState) => state.app.walletFallback;
export const selectProviderType = (state: RootState) => state.app.providerType;
export const selectNetwork = (state: RootState) => state.app.network;
export const selectAddress = (state: RootState) => state.app.address;
export const selectTheme = (state: RootState) => state.app.theme;
export const selectReachAccount = (state: RootState) => state.app.reachAccount;
export const selectBalance = (state: RootState) => state.app.balance;
export const selectIsConnecting = (state: RootState) => state.app.isConnecting;

export default appSlice.reducer;
