import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Box, FormGroup, FormControlLabel, Switch, Toolbar, Typography, Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ConnectButton from "./ConnectButton";
import { ProviderType, WalletFallbackType, Theme, NetworkType } from "types";
import { useAppDispatch } from "hooks/useStore";
import { toggleTheme } from "store/slices/app";
import AuctionSetupModal from "components/AuctionSetupModal";
import config from "config";

const brandNameEnv = process.env.REACT_APP_BRAND_NAME || config.REACT_APP_BRAND_NAME || "base";

interface Props {
  connecting: boolean;
  onConnect: () => void;
  onDisconnect: () => void;
  addr: string;
  bal: string;
  walletFallback: WalletFallbackType;
  providerType: ProviderType;
  network: NetworkType;
  theme?: Theme;
}

const ButtonAppBar: FC<Props> = (props) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const onChangeTheme = () => dispatch(toggleTheme());

  const [auctionSetupModalVisible, setAuctionSetupModalVisible] = useState<boolean>(false);

  const openAuctionSetupModal = () => setAuctionSetupModalVisible(true);
  const closeAuctionSetupModal = () => setAuctionSetupModalVisible(false);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Box bgcolor="background.default">
          <Toolbar>
            <Typography variant="h6" color="textPrimary" component="div" sx={{ flexGrow: 1 }}>
              <span onClick={() => navigate("/")}>
                <span>{String(brandNameEnv).toUpperCase()}</span>
              </span>
            </Typography>
            {props.addr && (
              <Button onClick={openAuctionSetupModal}>
                <AddCircleOutlineIcon />
              </Button>
            )}
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={props.theme === "dark"} onChange={onChangeTheme} />}
                label={<Typography color="textPrimary">Toggle Theme</Typography>}
              />
            </FormGroup>
            <ConnectButton {...props} />
          </Toolbar>
        </Box>
      </AppBar>

      <AuctionSetupModal
        open={auctionSetupModalVisible}
        onClose={closeAuctionSetupModal}
        walletFallback={props.walletFallback}
        providerType={props.providerType}
      />
    </Box>
  );
};

export default ButtonAppBar;
