import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Account, AssetDetails } from "types";
import { useStyle } from "pages/Home/styles";
import { Box, Grid, Typography } from "@mui/material";
import useCopy from "hooks/useCopy";
import { getAssetInfo, resolveAssetImage } from "services/api/algorand";
import { formatCompactAddress, getCollection } from "services/utils/algorand";
import { useAppSelector } from "hooks/useStore";
import { selectProviderType } from "store/slices/app";

interface Props {
  accounts: Account[];
  assets: AssetDetails[];
}

const CreatorList: FC<Props> = ({ accounts, assets }) => {
  const providerType = useAppSelector(selectProviderType);
  const navigate = useNavigate();
  const classes = useStyle();
  const copy = useCopy();
  const [assetInfo, setAssetInfo] = useState<any>(null);
  const [addr, setAddr] = useState<any>(null);

  const onClickTitle = (id: number) => {
    navigate(`/sale/${id}`);
  };

  const onClickAppId = (account: Account) => {
    copy(String(account.appId));
    alert("app id coppied to clipboard");
  };

  const onClickAddress = (account: Account) => {
    copy(account.address);
    alert("address coppied to clipboard");
  };

  const creators = Array.from(new Set(assets.map((el) => el.params.creator)));

  // -------------------------------------------------
  // EFFECT: retrieve asset info
  // -------------------------------------------------
  useEffect(() => {
    console.log({ assetInfo, providerType });
    if (assetInfo) return;
    if (providerType === "TestNet") {
      (async () => {
        const P: any = assets.map(resolveAssetImage);
        const assetInfo = await Promise.all(P);
        setAssetInfo(
          assetInfo.map((el: any) => ({
            media: {
              url: el
            }
          }))
        );
      })();
    } else {
      // TODO fix me later
      //getAssetInfo(String(asset.index)).then(setAssetInfo);
      getAssetInfo("325308062").then((el: any) => setAssetInfo([el]));
    }
  }, [assets, assetInfo]);
  // -------------------------------------------------

  return (
    <Box className={classes.list}>
      <Typography variant="h5" color="text.primary">
        Creators
      </Typography>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        {creators.map((creator, i) => {
          const creatorIndex = assets.map((el) => el?.params?.creator).indexOf(creator);
          return (
            <Grid key={`${creator}`} item xs={6} sm={4}>
              <Box
                sx={{
                  height: "calc(100% - 3rem)",
                  padding: "1rem",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: addr === creator ? "text.primary" : "text.secondary",
                  borderRadius: 2,
                  overflow: "hidden"
                }}>
                <Box>{assetInfo && <img src={assetInfo[creatorIndex]?.media?.url} alt={assets[i].params.name} />}</Box>
                <br />
                <Box onClick={() => (addr !== creator ? setAddr(creator) : setAddr(null))} sx={{ cursor: "pointer" }}>
                  <Typography variant="h5" color="text.primary">
                    {((name: string) => (name === creator ? formatCompactAddress(creator) : name))(
                      getCollection(creator, providerType).name
                    )}
                  </Typography>
                </Box>
                <br />
                &nbsp;
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Typography variant="h5" color="text.primary">
        Sales
      </Typography>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        {addr && creators.includes(addr)
          ? accounts.map((account, i) =>
              assets[i] && assets[i]?.params?.creator === addr ? (
                <Grid key={`${account.appId}-${assets[i].index}`} item xs={6} sm={4}>
                  <Box
                    sx={{
                      height: "calc(100% - 2rem)",
                      padding: "1rem",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderColor: "text.secondary",
                      borderRadius: 2
                    }}>
                    <Box>{assetInfo && <img src={assetInfo[i]?.media?.url} alt={assets[i].params.name} />}</Box>
                    <br />
                    <Box onClick={() => onClickTitle(account.appId)} sx={{ cursor: "pointer" }}>
                      <Typography variant="h5" color="text.primary">
                        {assets[i].params.name}
                      </Typography>
                    </Box>
                    <br />
                    <Typography variant="body2" color="text.primary">
                      appId:
                    </Typography>
                    &nbsp;
                    <Box onClick={() => onClickAppId(account)} sx={{ cursor: "pointer" }}>
                      <Typography variant="body2" color="primary">
                        {account.appId}-{assets[i].index}
                      </Typography>
                    </Box>
                    <span onClick={() => {}}></span>
                    <br />
                    <Typography variant="body2" color="text.primary">
                      address:
                    </Typography>
                    &nbsp;
                    <Box onClick={() => onClickAddress(account)} sx={{ cursor: "pointer" }}>
                      <Typography variant="body2" color="primary">
                        {account.address.slice(0, 8)}...{" "}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ) : null
            )
          : accounts.map((account, i) =>
              assets[i] ? (
                <Grid key={`${account.appId}-${assets[i].index}`} item xs={6} sm={3}>
                  <Box
                    sx={{
                      height: "calc(100% - 2rem)",
                      padding: "1rem",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderColor: "text.secondary",
                      borderRadius: 2
                    }}>
                    <Box>{assetInfo && <img src={assetInfo[i]?.media?.url} alt={assets[i].params.name} />}</Box>
                    <br />
                    <Box onClick={() => onClickTitle(account.appId)} sx={{ cursor: "pointer" }}>
                      <Typography variant="h5" color="text.primary">
                        {assets[i].params.name}
                      </Typography>
                    </Box>
                    <br />
                    <Typography variant="body2" color="text.primary">
                      appId:
                    </Typography>
                    &nbsp;
                    <Box onClick={() => onClickAppId(account)} sx={{ cursor: "pointer" }}>
                      <Typography variant="body2" color="primary">
                        {account.appId}-{assets[i].index}
                      </Typography>
                    </Box>
                    <span onClick={() => {}}></span>
                    <br />
                    <Typography variant="body2" color="text.primary">
                      address:
                    </Typography>
                    &nbsp;
                    <Box onClick={() => onClickAddress(account)} sx={{ cursor: "pointer" }}>
                      <Typography variant="body2" color="primary">
                        {account.address.slice(0, 8)}...{" "}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ) : null
            )}
      </Grid>
    </Box>
  );
};

export default CreatorList;
