import { useState, FC, MouseEvent } from "react";
import { ButtonGroup, Divider, MenuItem, Menu, Button, Box, Typography, CircularProgress } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useCopy from "hooks/useCopy";
import { formatCompactAddress } from "services/utils/algorand";
import Icon from "components/Icon";
import { ProviderType, WalletFallbackType, NetworkType } from "types";
import { useAppDispatch } from "hooks/useStore";
import { setNetwork, setProviderType, setWalletFallback } from "store/slices/app";
import { setNotificationMessage } from "store/slices/notification";
import config from "config";

const customDisabled =
  parseInt(process.env.REACT_APP_CONNECT_CUSTOM_DISABLED || config.REACT_APP_CONNECT_CUSTOM_DISABLED || "0", 10) > 0;
const networkDisabled =
  parseInt(process.env.REACT_APP_CONNECT_NETWORK_DISABLED || config.REACT_APP_CONNECT_NETWORK_DISABLED || "1", 10) > 0;
const algoDisabled =
  parseInt(process.env.REACT_APP_CONNECT_ALGO_DISABLED || config.REACT_APP_CONNECT_ALGO_DISABLED || "0", 10) > 0;
const ethDisabled =
  parseInt(process.env.REACT_APP_CONNECT_ETH_DISABLED || config.REACT_APP_CONNECT_ETH_DISABLED || "1", 10) > 0;
const cfxDisabled =
  parseInt(process.env.REACT_APP_CONNECT_CFX_DISABLED || config.REACT_APP_CONNECT_CFX_DISABLED || "1", 10) > 0;

interface Props {
  connecting: boolean;
  onConnect: () => void;
  onDisconnect: () => void;
  addr: string;
  bal: string;
  walletFallback: WalletFallbackType;
  providerType: ProviderType;
  network: NetworkType;
}

const ConnectButton: FC<Props> = ({
  connecting,
  onConnect,
  onDisconnect,
  addr,
  bal,
  walletFallback,
  providerType,
  network = "Algorand"
}) => {
  const dispatch = useAppDispatch();
  const copy = useCopy();
  const [anchorElConnect, setAnchorElConnect] = useState<null | HTMLElement>(null);
  const [anchorElAddress, setAnchorElAddress] = useState<null | HTMLElement>(null);

  const reloadWindow = () => {
    setTimeout(() => {
      window.location.reload();
    }, 30);
  };

  const handleClickConnect = (event: MouseEvent<HTMLButtonElement>) => {
    onConnect();
  };
  const handleClickAddress = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElAddress(event.currentTarget);
  };
  const handleCopyAddress = () => {
    copy(addr);
    dispatch(setNotificationMessage(`Address ${formatCompactAddress(addr)} is copied to the clipboard`));
  };
  const handleClickArrow = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElConnect(event.currentTarget);
  };
  const handleCloseConnect = () => {
    setAnchorElConnect(null);
  };
  const handleCloseAddress = () => {
    setAnchorElAddress(null);
  };
  const handleProviderSelect = (type: ProviderType) => {
    dispatch(setProviderType(type));
    reloadWindow();
  };
  const handleDisconnect = () => {
    setAnchorElAddress(null);
    setAnchorElConnect(null);

    onDisconnect();
  };
  const handleChangeWalletType = (type: WalletFallbackType) => {
    dispatch(setWalletFallback(type));
    reloadWindow();
  };
  const handleNetworkSelect = (net: NetworkType) => {
    dispatch(setNetwork(net));
    reloadWindow();
  };

  const PROVIDER_OPTIONS: ProviderType[] = ["MainNet", "TestNet"];
  const NETWORK_OPTIONS: NetworkType[] = [];
  if (!algoDisabled) {
    NETWORK_OPTIONS.push("Algorand");
  }
  if (!ethDisabled) {
    NETWORK_OPTIONS.push("Etherium");
  }
  if (!cfxDisabled) {
    NETWORK_OPTIONS.push("Conflux");
  }

  if (connecting) {
    return (
      <Box>
        <CircularProgress size={24} />
      </Box>
    );
  }

  return addr ? (
    <>
      <Button variant="outlined" onClick={handleClickAddress}>
        {formatCompactAddress(addr)}
        &nbsp;
        <Icon name="algo" width={24} height={24} />
        &nbsp;{bal}
      </Button>
      <Menu
        id="connect-btn-menu"
        anchorEl={anchorElAddress}
        open={Boolean(anchorElAddress)}
        onClose={handleCloseAddress}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}>
        <MenuItem sx={{ paddingRight: 20 }}>
          <Typography sx={{ paddingRight: 2 }}>{formatCompactAddress(addr)}</Typography>
          <ContentCopyIcon fontSize="small" onClick={handleCopyAddress} />
        </MenuItem>
        <MenuItem onClick={handleDisconnect}>Disconnect</MenuItem>
      </Menu>
    </>
  ) : (
    <>
      <ButtonGroup>
        <Button id="connect-btn-button" variant="outlined" onClick={handleClickConnect}>
          Connect
        </Button>
        {!customDisabled && (
          <Button variant="outlined" onClick={handleClickArrow} sx={{ paddingX: "0.25rem" }}>
            <KeyboardArrowDownIcon color="inherit" />
          </Button>
        )}
      </ButtonGroup>
      {!customDisabled && Boolean(anchorElConnect) && (
        <Menu
          id="connect-btn-menu"
          anchorEl={anchorElConnect}
          open={Boolean(anchorElConnect)}
          onClose={handleCloseConnect}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}>
          {[
            ...(network === "Algorand"
              ? [
                  // false ? (
                  //   <MenuItem onClick={() => handleChangeWalletType("Mnemonic")}>
                  //     <Box width="2.5rem" height="1.5rem">
                  //       {walletFallback === "Mnemonic" && <CheckIcon />}
                  //     </Box>
                  //     Mnemonic
                  //   </MenuItem>
                  // ) : null,
                  <MenuItem
                    key="MyAlgoConnect"
                    onClick={() => handleChangeWalletType("MyAlgoConnect")}
                    sx={{ paddingRight: 10 }}>
                    <Box width="2.5rem" height="1.5rem">
                      {walletFallback === "MyAlgoConnect" && <CheckIcon />}
                    </Box>
                    My Algo Connect
                  </MenuItem>,
                  <MenuItem key="WalletConnect" onClick={() => handleChangeWalletType("WalletConnect")}>
                    <Box width="2.5rem" height="1.5rem">
                      {walletFallback === "WalletConnect" && <CheckIcon />}
                    </Box>
                    Wallet Connect
                  </MenuItem>
                ]
              : []),
            ...[
              network === "Etherium" ? (
                <MenuItem disabled>
                  <Box width="2.5rem" height="1.5rem">
                    <CheckIcon />
                  </Box>
                  Metamask
                </MenuItem>
              ) : null,
              network === "Conflux" ? (
                <MenuItem disabled>
                  <Box width="2.5rem" height="1.5rem">
                    <CheckIcon />
                  </Box>
                  Fluent Wallet
                </MenuItem>
              ) : null,
              !networkDisabled
                ? [
                    <Divider key="network-divider" />,
                    ...NETWORK_OPTIONS.map((net) => (
                      <MenuItem key={net} onClick={() => handleNetworkSelect(net)}>
                        <Box width="2.5rem" height="1.5rem">
                          {network === net && <CheckIcon />}
                        </Box>
                        {net}
                      </MenuItem>
                    ))
                  ]
                : null,
              <Divider key="wallet-divider" />,

              ...PROVIDER_OPTIONS.map((net) => (
                <MenuItem key={net} onClick={() => handleProviderSelect(net)}>
                  <Box width="2.5rem" height="1.5rem">
                    {providerType === net && <CheckIcon />}
                  </Box>
                  {net}
                </MenuItem>
              ))
            ]
          ]}
        </Menu>
      )}
    </>
  );
};

export default ConnectButton;
