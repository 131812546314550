import { FC } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

const HomeLayout: FC<{}> = () => {
  return (
    <Box className="layout home">
      <Box
        className="layout__body"
        sx={{
          bgcolor: "background.paper"
        }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default HomeLayout;
