import { loadStdlib } from "@reach-sh/stdlib";
import { ALGO_MyAlgoConnect as MyAlgoConnect } from "@reach-sh/stdlib";
import { ALGO_WalletConnect as WalletConnect } from "@reach-sh/stdlib";
import { Stdlib_User } from "@reach-sh/stdlib/dist/types/interfaces";
import { ProviderType, WalletFallbackType } from "types";
import config from "config";

let reach: Stdlib_User<any, any, any, any, any, any, any, any, any, any>;

function getCurrentNetwork(): string {
  const networkEnv = process.env.REACT_APP_NETWORK || config.REACT_APP_NETWORK || "ALGO";
  return networkEnv;
}

export function getCurrentProvider(): ProviderType {
  const providerEnv: ProviderType = (localStorage.getItem("providerEnv") as ProviderType) || "TestNet";
  return providerEnv;
}

function reloadStdlib(
  walletFallback: WalletFallbackType,
  providerType: ProviderType
): Stdlib_User<any, any, any, any, any, any, any, any, any, any> {
  const networkEnv = getCurrentNetwork();
  const stdlib = loadStdlib({
    REACH_CONNECTOR_MODE: getCurrentNetwork()
    //REACH_DEBUG: "true"
  });

  let providerEnv;
  switch (providerType) {
    case "MainNet":
    case "MainNet-RandLabs":
    case "MainNet-AlgoNode":
      providerEnv = {
        ALGO_TOKEN: "",
        ALGO_SERVER: "https://mainnet-api.algonode.cloud",
        ALGO_PORT: "",
        ALGO_NODE_WRITE_ONLY: "no",

        ALGO_INDEXER_TOKEN: "",
        ALGO_INDEXER_SERVER: "https://mainnet-idx.algonode.cloud",
        ALGO_INDEXER_PORT: ""
      };
      break;
    case "TestNet":
    case "TestNet-RandLabs":
    case "TestNet-AlgoNode":
      providerEnv = {
        ALGO_TOKEN: "",
        ALGO_SERVER: "https://testnet-api.algonode.cloud",
        ALGO_PORT: "",
        ALGO_NODE_WRITE_ONLY: "no",

        ALGO_INDEXER_TOKEN: "",
        ALGO_INDEXER_SERVER: "https://testnet-idx.algonode.cloud",
        ALGO_INDEXER_PORT: ""
      };
      break;
  }

  if (networkEnv === "ALGO") {
    switch (walletFallback) {
      case "MyAlgoConnect":
        stdlib.setWalletFallback(
          stdlib.walletFallback({
            providerEnv,
            MyAlgoConnect
          })
        );

        break;
      case "WalletConnect":
        stdlib.setWalletFallback(
          stdlib.walletFallback({
            providerEnv,
            WalletConnect
          })
        );
        break;
      case "Mnemonic":
      default:
        stdlib.setWalletFallback(
          stdlib.walletFallback({
            providerEnv
          })
        );
        break;
    }
  }

  return stdlib;
}

/** Global default reach object */
export function useReach(
  walletFallback: WalletFallbackType,
  providerType: ProviderType
): Stdlib_User<any, any, any, any, any, any, any, any, any, any> {
  if (walletFallback && providerType) {
    reach = reloadStdlib(walletFallback, providerType);
  }
  return reach;
}
