import { FC, useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Autocomplete
} from "@mui/material";
import appService from "services/api/app";
import config from "services/utils/app/reverse/config";
import * as backend from "services/utils/app/reverse/build/index.main.mjs";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import { selectReachAccount } from "store/slices/app";
import { getAsset } from "services/api/algorand";
import { Asset, ProviderType, WalletFallbackType } from "types";
import { useReach } from "hooks/useReach";
import { setNotificationMessage, setNotificationSeverity } from "store/slices/notification";

interface Props {
  open: boolean;
  walletFallback: WalletFallbackType;
  providerType: ProviderType;
  onClose: () => void;
}

const AuctionSetupModal: FC<Props> = ({ open, onClose, walletFallback, providerType }) => {
  const reach = useReach(walletFallback, providerType);

  const dispatch = useAppDispatch();
  const account = useAppSelector(selectReachAccount);
  const [salePrice, setSalePrice] = useState<string>();
  const [token, setToken] = useState<string>("");
  const [address, setAddress] = useState<any>([undefined, undefined, undefined, undefined]);
  const [amount, setAmount] = useState<any>([0, 0, 0, 0]);
  const [loading, setLoading] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const tokenOptions =
    account?.assets
      ?.filter(({ amount }: Asset) => !!amount)
      .map((asset: Asset) => ({
        label: asset["asset-id"].toString(),
        value: asset["asset-id"]
      })) ?? [];

  const onChangeToken = (_e: any, newValue: any) => {
    if (typeof newValue === "string") {
      setToken(newValue);
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setToken(newValue.inputValue);
    } else {
      setToken(newValue.value);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const { info: app } = await appService.createApp(config.PLAN_ID);
      const ctc = account.contract(backend, app);
      const addr = account.networkAccount.addr;
      const {
        asset: {
          params: { decimals }
        }
      } = await getAsset(parseInt(token, 10));
      ctc.p.Contractee({});
      ctc.p.Auctioneer({
        getParams: async () => {
          const params = {
            token: token,
            tokenAmount: reach.bigNumberify(1 * 10 ** decimals),
            creator: addr,
            rewardAmount: reach.parseCurrency(1),
            startPrice: reach.parseCurrency(salePrice),
            floorPrice: reach.parseCurrency(salePrice),
            endSecs: reach.bigNumberToNumber(await reach.getNetworkSecs()) + 100,
            addrs: [
              "OJUQOEPFOEZUP3JJIF6OAV4RZQL6HQMBDIXODGSXNEIH7TTR353IMJEL24",
              ...address.map((el: any) => (el ? el : addr))
            ],
            /*
            addrs: [
              "OJUQOEPFOEZUP3JJIF6OAV4RZQL6HQMBDIXODGSXNEIH7TTR353IMJEL24",
              "MJNJMIZS3N5XZM2CL4344Y34RBMJZQT5WQJUYDWXBFEU6P7WCYQDXS7NV4",
              "7GDBWS6G5YB5TS4SYTY5IRKHYR5G7JZIAAIGNNSV3VFQJ7MOA3EZZ3QLFI",
              "GY3QCUF7JSYM6PRKDZKJ2A4FETIZL27AZH4JG4J6VRNREHBNOKEM5LIBDM",
              "FDG36VBCBUVJOCTJM4NV5ELOGLZGMN4HG53FVDYGNL5EY4PQEIERTRQ3UE"
            ],
            */
            distr: [1, ...amount.map((el: any) => (el ? parseInt(el) : 0))],
            /*
            distr: [1, 1, 2, 3, 2],
            */
            royaltyCap: Math.floor(parseFloat(salePrice ?? "1") * 10)
          };
          return params;
        }
      });
      ctc.p.Depositer({
        signal: () => {
          setLoading(false);
          onClose();
          setTimeout(() => window.location.reload(), 2000);
        }
      });
    } catch (error: any) {
      setError(error.message);
    }
  };

  useEffect(() => {
    setValid(!!(token && salePrice));
  }, [token, salePrice]);

  useEffect(() => {
    if (error) {
      dispatch(setNotificationSeverity("error"));
      dispatch(setNotificationMessage(error));
    }
  }, [error]);

  console.log(address);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New Auction</DialogTitle>
      <DialogContent>
        <DialogContentText color="textPrimary">
          Please fill in the following inputs to create a new auction.
        </DialogContentText>
        <Grid container spacing={2} sx={{ paddingY: 4 }}>
          <Grid item xs={6}>
            <TextField
              id="salePrice"
              label="Sale Price"
              type="number"
              variant="standard"
              value={salePrice}
              fullWidth
              onChange={(e) => setSalePrice(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                id="create-auction-token"
                options={tokenOptions}
                fullWidth
                sx={{ width: 240 }}
                renderInput={(params) => <TextField {...params} label="Asset ID" />}
                onChange={onChangeToken}
              />
            </FormControl>
          </Grid>
          {[...Array(4)].map((el, i) => (
            <Grid key={i} item xs={6}>
              <TextField
                id={`payoutAddr${i}`}
                label={`Payout address ${i}`}
                type="text"
                variant="standard"
                value={address[i]}
                fullWidth
                onChange={(e) => setAddress([...address.slice(0, i), e.target.value, ...address.slice(i + 1)])}
              />
            </Grid>
          ))}
          {[...Array(4)].map((el, i) => (
            <Grid key={i} item xs={6}>
              <TextField
                id={`payoutAmt${i}`}
                label={`Payout amount ${i}`}
                type="text"
                variant="standard"
                value={amount[i]}
                fullWidth
                onChange={(e) => setAmount([...amount.slice(0, i), e.target.value, ...amount.slice(i + 1)])}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} disabled={!valid || loading}>
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuctionSetupModal;
