/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { Button, Box, Paper, Grid, Container, CircularProgress, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { styled } from "@mui/material/styles";
import algosdk from "algosdk";
import { useReach } from "hooks/useReach";
import appService from "services/api/app";
import { getAccountInfo, getAsset } from "services/api/algorand";
import ContractLoader from "components/loaders/ContractLoader";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import { selectAddress, selectProviderType, selectWalletFallback, setAddress } from "store/slices/app";
import { AppViewData, ContractApp, GetAssetsData, GetAssetsVars } from "types";
import { useQuery } from "@apollo/client";
import { GET_ASSETS } from "services/api/ion-graphql";
import * as backend from "services/utils/app/reverse/build/index.main.mjs";
import appConfig from "services/utils/app/reverse/config";
import { getView } from "services/utils/app/reverse/functions";

function App() {
  const dispatch = useAppDispatch();
  const walletFallback = useAppSelector(selectWalletFallback);
  const providerType = useAppSelector(selectProviderType);
  const addr = useAppSelector(selectAddress);

  const reach = useReach(walletFallback, providerType);

  const setAddr = (value: string) => dispatch(setAddress(value));

  const initialState: any = {
    acc: null,
    addr: ""
  };
  const [state, setState] = useState<any>(initialState);
  const [loadingCreation, setLoadingCreation] = useState(false);
  const [loadingActivation, setLoadingActivation] = useState(false);
  const [apps, setApps] = useState<ContractApp[]>([]);
  const [app, setApp] = useState<number>();
  const [view, setView] = useState<any>(null);
  const [api, setApi] = useState<any>(null);
  const [query, setQuery] = useState<any>({});

  // Testing ion graphql api
  const { loading, error, data } = useQuery<GetAssetsData, GetAssetsVars>(GET_ASSETS, {
    variables: { firstAssetId: null, limit: 10, offset: 0, orderBy: "asset_id" }
  });

  useEffect(() => {
    if (addr) {
      handleConnect();
    }
  }, []);

  useEffect(() => {
    appService.getApps({ planId: appConfig.PLAN_ID, deleted: false }).then(setApps);
  }, []);

  // Testing assets apis
  useEffect(() => {
    if (apps?.length) {
      apps.forEach((app: ContractApp) => {
        const appAddress = algosdk.getApplicationAddress(app.appId);
        getAccountInfo(appAddress).catch(() => {
          // REM may prematuraly delete app if index takes more than 59 seconds to update
          //appService.deleteApp(app.appId);
        });
      });
    }
  }, [apps]);

  // TODO global connect state
  const handleConnect = async () => {
    try {
      let acc;
      if (addr) {
        acc = await reach.connectAccount({ addr });
      } else {
        acc = await reach.getDefaultAccount();
        setAddr(acc.networkAccount.addr);
      }
      //const balAtomic = await reach.balanceOf(acc);
      //const bal = reach.formatCurrency(balAtomic, 4);
      const balAtomic = null;
      const bal = 0;
      //const accInfo = await getAccountInfo(acc.networkAccount.addr);
      const accInfo = {};
      setState({
        ...state,
        acc: {
          ...acc,
          ...accInfo
        },
        addr,
        balAtomic,
        bal
      });
    } catch (e) {
      alert(e);
    }
  };

  const handleCreation = async () => {
    setLoadingCreation(true);
    const { info } = await appService.createApp(appConfig.PLAN_ID);
    setApps([...apps, { appId: info }]);
    setApp(info);
    setLoadingCreation(false);
  };

  const handleActivation = async () => {
    if (!state.acc || !app) return;
    setLoadingActivation(true);
    const ctc = await state.acc.contract(backend, app);
    await Promise.all([backend.Contractee(ctc, {})]);
    // refresh apps
    setApps(
      apps.filter(({ appId }): boolean => {
        return appId !== app;
      })
    );
    setLoadingActivation(false);
  };

  const handleDeletion = async () => {
    if (!app) return;
    await appService.deleteApp(app);
    setApps(apps.filter(({ appId }) => appId !== app)); // refresh apps
  };

  const handleCleanup = async () => {
    if (!state.acc) return;
    apps.forEach((app: ContractApp) => {
      const appAddress = algosdk.getApplicationAddress(app.appId);
      getAccountInfo(appAddress).then(({ account }) => {
        // use relay if amt gt 1 algo and no asset
        if (account.amount > 1000000 && account.assets.reduce((acc: any, val: any) => acc + val.amount, 0) === 0) {
          const ctc = state.acc.contract(backend, app.appId);
          ctc.p.Relay({}).then(() => appService.deleteApp(app.appId));
        }
      });
    });
  };

  const handleContractee = async () => {
    if (!state.acc || !app) return;
    const ctc = state.acc.contract(backend, app);
    ctc.p.Contractee({});
  };

  const handleAuctioneer = async () => {
    if (!state.acc || !app) return;
    const ctc = state.acc.contract(backend, app);
    const addr = state.acc.networkAccount.addr;
    const {
      asset: {
        params: { decimals }
      }
    } = await getAsset(query.ASSETID);
    ctc.p.Auctioneer({
      getParams: async () => ({
        token: query.ASSETID,
        tokenAmount: reach.bigNumberify(1 * 10 ** decimals),
        creator: addr,
        rewardAmount: reach.parseCurrency(1),
        startPrice: reach.parseCurrency(1000),
        floorPrice: reach.parseCurrency(10),
        endSecs: reach.bigNumberToNumber(await reach.getNetworkSecs()) + 120,
        addrs: [
          "OJUQOEPFOEZUP3JJIF6OAV4RZQL6HQMBDIXODGSXNEIH7TTR353IMJEL24",
          "MJNJMIZS3N5XZM2CL4344Y34RBMJZQT5WQJUYDWXBFEU6P7WCYQDXS7NV4",
          "7GDBWS6G5YB5TS4SYTY5IRKHYR5G7JZIAAIGNNSV3VFQJ7MOA3EZZ3QLFI",
          "GY3QCUF7JSYM6PRKDZKJ2A4FETIZL27AZH4JG4J6VRNREHBNOKEM5LIBDM",
          "FDG36VBCBUVJOCTJM4NV5ELOGLZGMN4HG53FVDYGNL5EY4PQEIERTRQ3UE"
        ],
        distr: [10, 1, 2, 3, 5],
        royaltyCap: 100
      })
    });
  };

  const handleDepositer = async () => {
    if (!state.acc || !app) return;
    const ctc = state.acc.contract(backend, app);
    ctc.p.Depositer({
      signal: () => {
        console.log("DEPOSIT");
      }
    });
  };

  const handleRelay = async () => {
    if (!state.acc || !app) return;
    const ctc = state.acc.contract(backend, app);
    await ctc.p.Relay({});
    appService.deleteApp(app);
  };

  const handleSubmit = async () => {
    setLoadingCreation(true);
    const { info: app } = await appService.createApp(appConfig.PLAN_ID);
    const ctc = state.acc.contract(backend, app);
    const addr = state.acc.networkAccount.addr;
    const {
      asset: {
        params: { decimals }
      }
    } = await getAsset(query.ASSETID);
    ctc.p.Contractee({});
    ctc.p.Auctioneer({
      getParams: async () => ({
        token: query.ASSETID,
        tokenAmount: reach.bigNumberify(1 * 10 ** decimals),
        creator: addr,
        rewardAmount: reach.parseCurrency(1),
        startPrice: reach.parseCurrency(1000),
        floorPrice: reach.parseCurrency(10),
        endSecs: reach.bigNumberToNumber(await reach.getNetworkSecs()) + 900,
        addrs: [
          "OJUQOEPFOEZUP3JJIF6OAV4RZQL6HQMBDIXODGSXNEIH7TTR353IMJEL24",
          "MJNJMIZS3N5XZM2CL4344Y34RBMJZQT5WQJUYDWXBFEU6P7WCYQDXS7NV4",
          "7GDBWS6G5YB5TS4SYTY5IRKHYR5G7JZIAAIGNNSV3VFQJ7MOA3EZZ3QLFI",
          "GY3QCUF7JSYM6PRKDZKJ2A4FETIZL27AZH4JG4J6VRNREHBNOKEM5LIBDM",
          "FDG36VBCBUVJOCTJM4NV5ELOGLZGMN4HG53FVDYGNL5EY4PQEIERTRQ3UE"
        ],
        distr: [10, 1, 2, 3, 5],
        royaltyCap: 100
      })
    });
    ctc.p.Depositer({
      signal: () => setLoadingCreation(false)
    });
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary
  }));

  const interact = {
    Contractee: {},
    Auctioneer: {
      getParams: {
        addrs: ["Address", "Address", "Address", "Address", "Address"],
        distr: ["UInt", "UInt", "UInt", "UInt", "UInt"],
        endSecs: "UInt",
        floorPrice: "Currency",
        royaltyCap: "Currency",
        startPrice: "Currency",
        token: "UInt"
      }
    },
    Depositer: {},
    Relay: {}
  };

  const Participants = ({ ctc }: { ctc?: any }) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          Participants
        </Grid>
        {Object.entries(ctc.p)
          .filter(([k]) => !["Constructor", "Verifier"].includes(k))
          .map(([k, v]: any[]) => (
            <Grid key={k} item>
              <Button disabled onClick={() => v((interact as any)[k])}>
                {k}
              </Button>
            </Grid>
          ))}
      </Grid>
    );
  };

  const View = ({ ctc }: { ctc?: any }) => {
    const [data, setData] = useState<AppViewData>();
    useEffect(() => {
      if (data) return;
      getView(reach)(ctc).then(setData);
    });
    return (
      <>
        {data ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              View
            </Grid>
            {Object.entries(data).map(([k, v]) => (
              <Grid key={k} item>
                <Item>
                  {k}: {v}
                </Item>
              </Grid>
            ))}
            <Grid item>
              <RefreshIcon onClick={() => setData(undefined)} />
            </Grid>
          </Grid>
        ) : (
          "Loading view..."
        )}
      </>
    );
  };

  const API = ({ ctc }: { ctc?: any }) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          Api
        </Grid>
        {Object.entries(ctc.a.Bid).map(([k, v]: any[]) => (
          <Grid key={k} item>
            <Button onClick={() => v()}>{k}</Button>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Container sx={{}}>
      <Box sx={{ p: 5 }}>
        {!loadingCreation ? (
          <Button variant="contained" onClick={handleCreation}>
            Create
          </Button>
        ) : (
          <Button disabled>
            <CircularProgress />
          </Button>
        )}
      </Box>
      <Box sx={{ p: 5 }}>
        <Button variant="contained" onClick={handleActivation}>
          Activate
        </Button>
      </Box>
      <Box sx={{ p: 5 }}>
        <Button variant="contained" onClick={handleDeletion}>
          Delete
        </Button>
      </Box>
      <Box sx={{ p: 5 }}>
        <Button variant="contained" onClick={handleCleanup}>
          Cleanup
        </Button>
      </Box>
      {apps && (
        <Box sx={{ p: 5 }}>
          <Grid container spacing={2}>
            {apps.map((el: { appId: any }, idx: any) => (
              <Grid key={el.appId} item xs={4}>
                <Item
                  onClick={async () => {
                    if (!state.acc) return;
                    const { appId } = el;
                    setApp(appId);
                  }}>
                  <Typography color="textPrimary" style={{ fontWeight: el.appId === app ? "bold" : "normal" }}>
                    {el.appId}
                  </Typography>
                </Item>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {app && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              Participant: Contractee
            </Grid>
            <Grid item xs={12}>
              <button onClick={handleContractee}>Contractee</button>
            </Grid>
          </Grid>
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              Participant: Auctioneer
            </Grid>
            <Grid item xs={12}>
              {["ASSETID"].map((el) => (
                <Box key={el} sx={{ p: 5 }}>
                  <label>{el}</label>
                  &nbsp;
                  <input name={el} id={el} onChange={({ target }) => setQuery({ ...query, [el]: target.value })} />
                </Box>
              ))}
            </Grid>
            <Grid item xs={12}>
              <button onClick={handleAuctioneer}>Auctioneer</button>
            </Grid>
          </Grid>
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              Participant: Depositer
            </Grid>
            <Grid item xs={12}>
              <button onClick={handleDepositer}>Depositer</button>
            </Grid>
          </Grid>
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              Participant: Relay
            </Grid>
            <Grid item xs={12}>
              <button onClick={handleRelay}>Relay</button>
            </Grid>
          </Grid>
        </>
      )}
      {app && state && reach && (
        <ContractLoader acc={state.acc} stdlib={reach} appId={app} backend={backend}>
          {(otherProps: any) => (
            <>
              <hr />
              <Participants {...otherProps} />
              <hr />
              <View {...otherProps} />
              <hr />
              <API {...otherProps} />
            </>
          )}
        </ContractLoader>
      )}
      <>
        <hr />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="text.primary">Submission</Typography>
          </Grid>
          <Grid item xs={6}>
            {["ASSETID"].map((el) => (
              <Box key={el} sx={{ p: 5, display: "flex" }}>
                <label>
                  <Typography color="text.primary">{el}</Typography>
                </label>
                &nbsp;
                <input name={el} id={el} onChange={({ target }) => setQuery({ ...query, [el]: target.value })} />
              </Box>
            ))}
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ p: 5 }}>
              <Button variant="contained" onClick={handleSubmit}>
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </>
    </Container>
  );
}

export default App;
