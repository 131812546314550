import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  root: {},
  top: {
    display: "flex"
  },
  topLeft: {
    flex: 1,
    padding: 16,

    "& img": {
      width: "100%",
      height: "100%‰"
    }
  },
  topRight: {
    flex: 2,
    padding: "16px 24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around"
  },
  list: {
    padding: 16
  }
});
