import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import appReducer from "./slices/app";
import notificationReducer from "./slices/notification";
import auctionReducer from "./slices/auction";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["notification", "app"]
};

const appPersistConfig = {
  key: "app",
  storage,
  blacklist: ["isConnecting", "reachAccount"]
};

const combinedReducers = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  notification: notificationReducer,
  auction: auctionReducer
});

const persistedReducer = persistReducer(persistConfig, combinedReducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
