import axios from "axios";
import { ContractApp, CreateAppResType, ProviderType } from "types";
import config from "config";

const getConfig = () => {
  const providerEnv: ProviderType = (localStorage.getItem("providerEnv") as ProviderType) || "TestNet";
  let api_endpoint: string;
  if (providerEnv === "MainNet") {
    api_endpoint =
      process.env.REACT_APP_API_ENDPOINT_MAINNET || config.REACT_APP_API_ENDPOINT_MAINNET || "http://localhost:5000";
  } else {
    api_endpoint =
      process.env.REACT_APP_API_ENDPOINT_TESTNET || config.REACT_APP_API_ENDPOINT_TESTNET || "http://localhost:5000";
  }
  return {
    api_endpoint
  };
};

const appService = {
  getApps: async (params = {}): Promise<ContractApp[]> => {
    return (await axios.get(`${getConfig().api_endpoint}/api/v2/apps`, { params }))?.data || 0;
  },
  createApp: async (id: string, params = {}): Promise<CreateAppResType> => {
    return (await axios.post(`${getConfig().api_endpoint}/api/v2/launch`, { id, params }))?.data || 0;
  },
  deleteApp: async (id: number) => {
    return (await axios.delete(`${getConfig().api_endpoint}/api/v2/apps/${id}`))?.data || 0;
  }
};

export default appService;
