import { FC, ReactElement } from "react";
import { Typography } from "@mui/material";
import { useAppSelector } from "hooks/useStore";
import { selectAddress } from "store/slices/app";

interface Props {
  children: ReactElement<any, any> | null;
}

const WalletRequiredRoute: FC<Props> = ({ children }) => {
  const address = useAppSelector(selectAddress);

  if (!address) {
    return <Typography color="textSecondary">Connect wallet to view page</Typography>;
  }

  return children;
};

export default WalletRequiredRoute;
