import React, { useEffect, useState } from "react";
import LoadingIndicator from "components/LoadingIndicator";

interface Props {
  acc: any;
  appId: number;
  stdlib: any;
  children: (props: any) => React.ReactElement;
  backend: any;
}

interface State {
  ctc: any;
  appId: number;
}

const ContractLoader: React.FC<Props> = ({ acc, appId, stdlib, children, backend }) => {
  const [state, setState] = useState<State>();

  useEffect(() => {
    if (acc) {
      const ctc = acc.contract(backend, appId);
      setState({
        ctc,
        appId
      });
    }
  }, [appId, acc]);

  return <>{state ? children(state) : <LoadingIndicator text="Loading contract..." />}</>;
};

export default ContractLoader;
