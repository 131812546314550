import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import algosdk from "algosdk";
import { getAccountAssets, getAsset } from "services/api/algorand";
import appService from "services/api/app";
import { RootState } from "store";
import { Account, AssetDetails, ContractApp } from "types";
import config from "../../services/utils/app/reverse/config";

export const fetchApps = createAsyncThunk("auction/fetchApps", async () => {
  const response = await appService.getApps({ planId: config.PLAN_ID, deleted: false });
  return response;
});

export const fetchAccounts = createAsyncThunk("auction/fetchAccounts", async (appIds: number[]) => {
  const response = await Promise.all(
    appIds.map(async (appId) => {
      const appAddress = algosdk.getApplicationAddress(appId);
      const appAssets = (await getAccountAssets(appAddress)).assets; // use /accounts/addr/assets because /accounts/addr breaks on accounts with many assets
      return {
        appId,
        address: appAddress,
        assets: appAssets
      };
    })
  );
  return response.filter((el) => (el?.assets ?? []).reduce((acc: number, val: Account) => (val?.amount ?? 0) + acc, 0));
});

export const fetchAssets = createAsyncThunk("auction/fetchAssets", async (accounts: Account[]) => {
  const assetIds: number[] = accounts.reduce<number[]>((acc: number[], current) => {
    return acc.concat((current.assets ?? []).map((asset) => asset["asset-id"]));
  }, []);
  const response = await Promise.all(assetIds.map((assetId) => getAsset(assetId)));
  return response.map((item) => item.asset);
});

interface AuctionState {
  apps: ContractApp[];
  accounts: Account[];
  assets: AssetDetails[];
  isLoading: boolean;
}

const initialState: AuctionState = {
  apps: [],
  accounts: [],
  assets: [],
  isLoading: false
};

export const auctionSlice = createSlice({
  name: "auction",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchApps.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchApps.fulfilled, (state, action) => {
      state.isLoading = false;
      state.apps = action.payload;
    });
    builder.addCase(fetchApps.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchAccounts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAccounts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.accounts = action.payload;
    });
    builder.addCase(fetchAccounts.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchAssets.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAssets.fulfilled, (state, action) => {
      state.isLoading = false;
      state.assets = action.payload;
    });
    builder.addCase(fetchAssets.rejected, (state, action) => {
      state.isLoading = false;
    });
  }
});

export const selectApps = (state: RootState) => state.auction.apps;
export const selectAccounts = (state: RootState) => state.auction.accounts;
export const selectAssets = (state: RootState) => state.auction.assets;
export const selectLoadingApps = (state: RootState) => state.auction.isLoading;

export default auctionSlice.reducer;
