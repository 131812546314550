import { createTheme } from "@mui/material";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#f2f2f2",
      paper: "#fff"
    },
    text: {
      primary: "#111",
      secondary: "#ccc"
    }
  }
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#222",
      paper: "#888"
    },
    text: {
      primary: "#fff",
      secondary: "#888"
    }
  }
});
