import * as React from "react";
import { Alert, Snackbar } from "@mui/material";
import { useReach } from "hooks/useReach";
import ButtonAppBar from "./ButtonAppBar";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import {
  fetchAccountInfo,
  handleConnectWallet,
  resetAccount,
  selectAddress,
  selectBalance,
  selectIsConnecting,
  selectNetwork,
  selectProviderType,
  selectReachAccount,
  selectTheme,
  selectWalletFallback
} from "store/slices/app";
import {
  selectNotificationMessage,
  selectNotificationSeverity,
  setNotificationMessage,
  setNotificationSeverity
} from "store/slices/notification";

const MAX_NOTIFICATION_LENGTH = 1000;

const CustomAppBar = () => {
  const dispatch = useAppDispatch();
  const addr = useAppSelector(selectAddress);
  const walletFallback = useAppSelector(selectWalletFallback);
  const providerType = useAppSelector(selectProviderType);
  const network = useAppSelector(selectNetwork);
  const theme = useAppSelector(selectTheme);
  const notificationMsg = useAppSelector(selectNotificationMessage);
  const notificationSvt = useAppSelector(selectNotificationSeverity);
  const balance = useAppSelector(selectBalance);
  const isConnecting = useAppSelector(selectIsConnecting);
  const reachAccount = useAppSelector(selectReachAccount);

  const reach = useReach(walletFallback, providerType);

  const [snackVisible, setSnackVisible] = React.useState(false);

  const handleCloseSnack = () => {
    dispatch(setNotificationSeverity("info"));
    dispatch(setNotificationMessage(""));
  };

  const handleConnect = React.useCallback(async () => {
    dispatch(handleConnectWallet({ reach, walletFallback }));
  }, [reach, walletFallback]);

  const handleDisconnect = () => {
    dispatch(resetAccount());
  };

  React.useEffect(() => {
    if (addr) {
      dispatch(setNotificationMessage(`You are using ${walletFallback} ${providerType}.`));
    }
  }, [addr, walletFallback, providerType]);

  React.useEffect(() => {
    setSnackVisible(!!notificationMsg);
  }, [notificationMsg]);

  React.useEffect(() => {
    if (addr && !reachAccount) {
      dispatch(fetchAccountInfo({ reach, address: addr }));
    }
  }, [addr, reachAccount]);

  return (
    <>
      <ButtonAppBar
        connecting={isConnecting}
        onConnect={handleConnect}
        onDisconnect={handleDisconnect}
        addr={addr ?? ""}
        bal={Number(balance).toFixed(2)}
        walletFallback={walletFallback}
        providerType={providerType}
        network={network}
        theme={theme}
      />

      {/** Global Notification start */}
      <Snackbar
        key="nav-snackbar"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackVisible}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
        sx={{
          maxWidth: "80vw"
        }}>
        <Alert onClose={handleCloseSnack} severity={notificationSvt} sx={{ width: "100%", wordBreak: "break-word" }}>
          {notificationMsg.length > MAX_NOTIFICATION_LENGTH
            ? `${notificationMsg.substring(0, MAX_NOTIFICATION_LENGTH)}...`
            : notificationMsg}
        </Alert>
      </Snackbar>
      {/** Global Notification end */}
    </>
  );
};

export default CustomAppBar;
