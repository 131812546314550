import collection from "assets/collection";
import { ProviderType } from "types";

export const getMinBalance = (ai: any) => {
  const appsTotalSchema = ai["apps-total-schema"];
  const createdApps = ai["created-apps"];
  const numByteSlice = appsTotalSchema["num-byte-slice"];
  const numUInt = appsTotalSchema["num-uint"];
  const { assets } = ai;
  return (
    assets.length * 100000 +
    (25000 + 3500) * numUInt +
    (25000 + 25000) * numByteSlice +
    100000 * createdApps.length +
    100000
  );
};

/*
 * splitAddres
 * convers text containing addrs to addr array
 */
export const splitAddrs = (addrs: string | number) =>
  String(addrs)
    .replace(/ /g, ",")
    .replace(/,/g, String.fromCharCode(10))
    .split(String.fromCharCode(10))
    .map((el) => String(el).trim())
    .filter((el) => !!el);

/*
 * formatMnemonic
 * - formates mnemonic phrase for reach stdlib new account from mnemonic func
 * + accepts comma and space separated formats
 */
export const formatMnemonic = (mn: string = "") => mn.replace(/, /g, " ") || "";

export const formatCompactAddress = (address: string) =>
  String(address).substr(0, 5).concat("...").concat(String(address).substr(-5));

export const getStoredAsset = (assetId: string) => {
  const providerEnv = localStorage.getItem("providerEnv") || "TestNet";
  return ((key: string) => JSON.parse(localStorage.getItem(key) ?? "{}"))(
    `${providerEnv.toLocaleLowerCase()}-asset-${assetId}`
  );
};

// FORMAT CURRENCY

/**
 * Collection of functions for formatCurrency custom utility.
 * will eventually be moved to stdlib
 */
function ldrop(str: string, char: string) {
  while (str[0] === char) {
    // eslint-disable-next-line no-param-reassign
    str = str.slice(1);
  }
  return str;
}

function rdrop(str: string, char: string) {
  while (str[str.length - 1] === char) {
    // eslint-disable-next-line no-param-reassign
    str = str.slice(0, str.length - 1);
  }
  return str;
}

function lpad(str: string, padChar: string, nChars: number) {
  const padding = padChar.repeat(Math.max(nChars - str.length, 0));
  return padding + str;
}

export const somethingFromSome =
  (f: (arg: any) => void, d: any) =>
  (some = []) =>
    some[1] ? f(some[1]) : d;

export const formatCurrencyFromSome2 = (stdlib: any, some: any, decimal = 4) =>
  somethingFromSome((some) => formatCurrency(stdlib)(some, decimal), 0)(some);

export const formatCurrency =
  (stdlib: any) =>
  (amt: any, decimals = 6): string => {
    if (!(Number.isInteger(decimals) && decimals >= 0)) {
      throw Error(`Expected decimals to be a nonnegative integer, but got ${decimals}.`);
    }
    const amtStr = stdlib.bigNumberify(amt).toString();
    const splitAt = Math.max(amtStr.length - decimals, 0);
    const lPredropped = amtStr.slice(0, splitAt);
    const l = ldrop(lPredropped, "0") || "0";
    if (decimals === 0) {
      return l;
    }
    const rPre = lpad(amtStr.slice(splitAt), "0", decimals);
    const rSliced = rPre.slice(0, decimals);
    const r = rdrop(rSliced, "0");

    return r ? `${l}.${r}` : l;
  };

// PARSE CURRENCY

/**
 *
 * @param {string|number} addr
 * @param {number} decimals (optional)
 * @returns big number to consumed by a reach program
 */
export const getAmtForContract = (stdlib: any) => (amount: any, decimals: number) => {
  const numericAmt: number = stdlib.isBigNumber(amount)
    ? amount.toNumber()
    : typeof amount === "string"
    ? parseFloat(amount)
    : typeof amount === "bigint"
    ? Number(amount)
    : amount;
  const conUnit = getConUnit(decimals);
  const value = numericAmt * conUnit;
  return stdlib.bigNumberify(Math.floor(value));
};

export const getConUnit = (decimals: number) => 10 ** decimals;

export const getCollection = (addr: string, provider: ProviderType) => {
  const col: any = provider === "TestNet" ? collection.TestNet : collection.MainNet;
  return addr in col ? col[addr] : addr;
};
