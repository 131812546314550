import { useEffect, useState } from "react";
import { Button, Box, Container } from "@mui/material";
import { useReach } from "hooks/useReach";
import { getAssetInfo } from "services/api/algorand";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import { selectAddress, selectProviderType, selectWalletFallback, setAddress } from "store/slices/app";
import { useParams } from "react-router-dom";

function App() {
  const { assetId } = useParams();

  const dispatch = useAppDispatch();
  const walletFallback = useAppSelector(selectWalletFallback);
  const providerType = useAppSelector(selectProviderType);
  const addr = useAppSelector(selectAddress);

  const reach = useReach(walletFallback, providerType);

  const setAddr = (value: string) => dispatch(setAddress(value));

  const initialState: any = {
    acc: null,
    addr: ""
  };
  const [state, setState] = useState<any>(initialState);
  const [asset, setAsset] = useState<any>(null);
  const [message, setMessage] = useState<string>("Loading...");

  useEffect(() => {
    if (addr) {
      handleConnect();
    }
  }, []);

  useEffect(() => {
    const id = parseInt(assetId ?? "0");
    if (id <= 0) return;
    getAssetInfo(`${id}`)
      .then((el) => {
        setMessage("");
        setAsset(el);
      })
      .catch(() => setMessage("Asset not found"));
  }, []);

  // TODO global connect state
  const handleConnect = async () => {
    try {
      let acc;
      if (addr) {
        acc = await reach.connectAccount({ addr });
      } else {
        acc = await reach.getDefaultAccount();
        setAddr(acc.networkAccount.addr);
      }
      //const balAtomic = await reach.balanceOf(acc);
      //const bal = reach.formatCurrency(balAtomic, 4);
      const balAtomic = null;
      const bal = 0;
      //const accInfo = await getAccountInfo(acc.networkAccount.addr);
      const accInfo = {};
      setState({
        ...state,
        acc: {
          ...acc,
          ...accInfo
        },
        addr,
        balAtomic,
        bal
      });
    } catch (e) {
      alert(e);
    }
  };

  return (
    <>
      <Container className="p-5">
        <Box>Asset</Box>
        <Box>{message}</Box>
        {asset && (
          <>
            <Box>
              <img src={asset.media.url} />
            </Box>
            <Box>Asset id: {assetId}</Box>
            <Box>Collection: AlgoSkull</Box>
            <Box>Offers:</Box>
            <Box>Sale history:</Box>
            <Box>
              Buttons:
              <br />
              <Button>Offer</Button>
              <Button>Buy</Button>
              <Button>Auction</Button>
            </Box>
          </>
        )}
      </Container>
    </>
  );
}

export default App;
