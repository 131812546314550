export default {
  REACT_APP_BRAND_NAME: "ArtRepublic",
  REACT_APP_API_ENDPOINT_MAINNET: "", // TODO update
  REACT_APP_API_ENDPOINT_TESTNET: "https://launcher.c934aa69.testnet.zestbloom.com",
  REACT_APP_ION_GRAPHQL_URL: "https://ion.testnet.zestbloom.com/graphql",
  REACT_APP_CONNECT_CUSTOM_DISABLED: "0",
  REACT_APP_CONNECT_NETWORK_DISABLED: "1",
  REACT_APP_CONNECT_ALGO_DISABLED: "0",
  REACT_APP_CONNECT_ETH_DISABLED: "1",
  REACT_APP_CONNECT_CFX_DISABLED: "1",
  REACT_APP_NETWORK: "ALGO",
  REACT_APP_API_ENDPOINT_ALGO_EXPLORER_API_MAINNET: "https://node.algoexplorerapi.io",
  REACT_APP_API_ENDPOINT_ALGO_EXPLORER_MAINNET: "https://algoexplorer.io",
  REACT_APP_API_ENDPOINT_ALGO_INDEXER_MAINNET: "https://algoindexer.algoexplorerapi.io",
  REACT_APP_API_ENDPOINT_ALGO_ION_MAINNET: "https://ion.testnet.zestbloom.com", // TODO update
  REACT_APP_API_ENDPOINT_ALGO_EXPLORER_API_TESTNET: "https://node.testnet.algoexplorerapi.io",
  REACT_APP_API_ENDPOINT_ALGO_EXPLORER_TESTNET: "https://testnet.algoexplorer.io",
  REACT_APP_API_ENDPOINT_ALGO_INDEXER_TESTNET: "https://algoindexer.testnet.algoexplorerapi.io",
  REACT_APP_API_ENDPOINT_ALGO_ION_TESTNET: "https://ion.testnet.zestbloom.com"
};
