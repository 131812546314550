import { AlertColor } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

interface NotificationState {
  message: string;
  severity: AlertColor;
}

const initialState: NotificationState = {
  message: "",
  severity: "info"
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotificationMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    setNotificationSeverity: (state, action: PayloadAction<AlertColor>) => {
      state.severity = action.payload;
    }
  }
});

export const { setNotificationMessage, setNotificationSeverity } = notificationSlice.actions;

export const selectNotificationMessage = (state: RootState) => state.notification.message;
export const selectNotificationSeverity = (state: RootState) => state.notification.severity;

export default notificationSlice.reducer;
