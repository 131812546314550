import { FC } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { selectTheme } from "store/slices/app";
import HomeLayout from "layouts/Home";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import Asset from "pages/Asset/Asset";
import Auction from "pages/Auction";
import CustomAppBar from "components/AppBar";
import Sandbox from "pages/Sandbox/Reverse";
import { darkTheme, lightTheme } from "theme";
import Footer from "components/Footer";
import WalletRequiredRoute from "layouts/WalletRequiredRoute";

import "./App.scss";

const App: FC = () => {
  const theme = useSelector(selectTheme);

  return (
    <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
      <div className="header">
        <Routes>
          <Route path="*" element={<CustomAppBar />} />
        </Routes>
      </div>

      <div className="main">
        <Routes>
          <Route path="/" element={<HomeLayout />}>
            <Route index element={<Home />} />
            <Route
              path="sandbox"
              element={
                <WalletRequiredRoute>
                  <Sandbox />
                </WalletRequiredRoute>
              }
            />
            <Route
              path="asset/:assetId"
              element={
                <WalletRequiredRoute>
                  <Asset />
                </WalletRequiredRoute>
              }
            />
            <Route
              path="sale/:id"
              element={
                <WalletRequiredRoute>
                  <Auction />
                </WalletRequiredRoute>
              }
            />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>

      <div className="footer">
        <Routes>
          <Route path="/*" element={<Footer />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
};

export default App;
