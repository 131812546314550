import axios from "axios";
import { ProviderType } from "types";
import config from "config";

const getConfig = () => {
  const providerEnv: ProviderType = (localStorage.getItem("providerEnv") as ProviderType) || "TestNet";

  let algoexplorerapi_endpoint: string;
  let algoexplorer_endpoint: string;
  let indexer_endpoint: string;
  let algoion_endpoint: string;

  if (providerEnv === "MainNet") {
    algoexplorerapi_endpoint =
      process.env.REACT_APP_API_ENDPOINT_ALGO_EXPLORER_API_MAINNET ||
      config.REACT_APP_API_ENDPOINT_ALGO_EXPLORER_API_MAINNET ||
      "https://node.algoexplorerapi.io";
    algoexplorer_endpoint =
      process.env.REACT_APP_API_ENDPOINT_ALGO_EXPLORER_MAINNET ||
      config.REACT_APP_API_ENDPOINT_ALGO_EXPLORER_MAINNET ||
      "https://algoexplorer.io";
    indexer_endpoint =
      process.env.REACT_APP_API_ENDPOINT_ALGO_INDEXER_MAINNET ||
      config.REACT_APP_API_ENDPOINT_ALGO_INDEXER_MAINNET ||
      "https://algoindexer.algoexplorerapi.io";
    algoion_endpoint =
      process.env.REACT_APP_API_ENDPOINT_ALGO_ION_MAINNET ||
      config.REACT_APP_API_ENDPOINT_ALGO_ION_MAINNET ||
      "https://ion.testnet.zestbloom.com";
  } else {
    algoexplorerapi_endpoint =
      process.env.REACT_APP_API_ENDPOINT_ALGO_EXPLORER_API_TESTNET ||
      config.REACT_APP_API_ENDPOINT_ALGO_EXPLORER_API_TESTNET ||
      "https://node.testnet.algoexplorerapi.io";
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    algoexplorer_endpoint =
      process.env.REACT_APP_API_ENDPOINT_ALGO_EXPLORER_TESTNET ||
      config.REACT_APP_API_ENDPOINT_ALGO_EXPLORER_TESTNET ||
      "https://testnet.algoexplorer.io";
    indexer_endpoint =
      process.env.REACT_APP_API_ENDPOINT_ALGO_INDEXER_TESTNET ||
      config.REACT_APP_API_ENDPOINT_ALGO_INDEXER_TESTNET ||
      "https://algoindexer.testnet.algoexplorerapi.io";
    algoion_endpoint =
      process.env.REACT_APP_API_ENDPOINT_ALGO_ION_TESTNET ||
      config.REACT_APP_API_ENDPOINT_ALGO_ION_TESTNET ||
      "https://ion.testnet.zestbloom.com";
  }

  return {
    algoexplorer_endpoint,
    algoexplorerapi_endpoint,
    indexer_endpoint,
    algoion_endpoint
  };
};

// TODO copy base dapp path update
export const getAssetInfo = async (assetIndex: string) =>
  (await axios.get(`${getConfig().algoion_endpoint}/assets/algo/${assetIndex}`))?.data;

export const getAccountInfo = async (addr: string, params = {}) => {
  return (await axios.get(`${getConfig().indexer_endpoint}/v2/accounts/${addr}`, { params }))?.data;
};

export const getAccountAssets = async (addr: string) => {
  return (await axios.get(`${getConfig().indexer_endpoint}/v2/accounts/${addr}/assets`))?.data;
};

export const getAppInfo = async (appId: string) =>
  await axios.get(`${getConfig().algoexplorerapi_endpoint}/v2/applications/${appId}`);

export const searchV1 = async (name: string) =>
  (await axios.get(`${getConfig().algoexplorerapi_endpoint}/v1/search/${name}`))?.data;

export const searchAssets = async (params = {}) =>
  (
    await axios.get(`${getConfig().indexer_endpoint}/v2/assets`, {
      params: {
        ...params
      }
    })
  )?.data;

export const getRichList = async (assetIndex: string, params = {}) =>
  (
    await axios.get(`${getConfig().indexer_endpoint}/stats/v2/accounts/rich-list`, {
      params: {
        ...params,
        "asset-id": assetIndex
      }
    })
  )?.data;

export const getHolders = async (assetIndex: string, params = {}) =>
  (
    await axios.get(`${getConfig().indexer_endpoint}/v2/accounts`, {
      params: {
        ...params,
        "asset-id": assetIndex
      }
    })
  )?.data;

export const getAsset = async (assetIndex: number) =>
  (await axios.get(`${getConfig().indexer_endpoint}/v2/assets/${assetIndex}`))?.data;

export const getAssetBalances = async (assetIndex: string, params = {}) =>
  (
    await axios.get(`${getConfig().indexer_endpoint}/v2/assets/${assetIndex}/balances`, {
      params
    })
  )?.data;

export const getAllAssetBalances = async (assetIndex: string) => {
  let balances = [];
  let next = null;
  do {
    let res;
    if (next) {
      res = await getAssetBalances(assetIndex, {
        "include-all": false,
        limit: 1000,
        next
      });
    } else {
      res = await getAssetBalances(assetIndex, {
        "include-all": false,
        limit: 1000
      });
    }
    next = res["next-token"];
    balances.push(res?.balances || []);
  } while (next);
  return balances.flatMap((el) => el);
};

// image utils

export const getUrlPath = (url: string) => ((delimeter) => url.split(delimeter).slice(2).join(delimeter))("/");

export const placeholderImage = "https://via.placeholder.com/1024";

export const getCFIPFSUrl = (path: string) => `https://cloudflare-ipfs.com/ipfs/${path}`;

export const getCFIPFS = async (path: string) => await axios.get(getCFIPFSUrl(path));

const urlToIPFSGatewayURL = (url: string) => "https://cloudflare-ipfs.com/ipfs/" + url.split("/").slice(2).join("/");

export const resolveAssetImage = async (asset: any) => {
  console.log({ asset });
  let url = asset?.params?.url;
  console.log(url);
  if (url === undefined) {
    return placeholderImage;
  }
  // is ARC3
  if (url.indexOf("#arc3") !== -1) {
    let res = (await axios.get(urlToIPFSGatewayURL(url)))?.data;
    if (res.image) {
      // TODO validate
      // decimal 0
      // image_minemine in whitelist
      return urlToIPFSGatewayURL(res.image);
    }
  }
  // is DUMB NFT
  // or ARC69
  if (url.indexOf("ipfs://") === 0) {
    return urlToIPFSGatewayURL(url);
  }
  return placeholderImage;
};

export const fetchAssetImage = async (assetIndex: number) => {
  let image;
  let contentType;
  try {
    const asset = (await getAsset(assetIndex))?.data;
    let url = asset?.url;
    if (url === undefined) {
      return placeholderImage;
    }
    if (url.indexOf("ipfs://") === 0) {
      return "https://cloudflare-ipfs.com/ipfs/" + url.split("/").slice(2).join("/");
    }
    let res = await axios.get(url);
    contentType = res?.headers["content-type"] ?? "";

    switch (contentType) {
      case "image/jpeg":
      case "image/png":
      case "image/gif":
        image = url;
        return image;
      case "text/json":
      default:
        break;
    }
    if (url.indexOf("ipfs") !== -1) {
      if (url.indexOf("json") !== -1) {
        // url is metadata json with relative path to image
        const maybeImage = (await getCFIPFS(getUrlPath(url))).data;
        var path = require("path");
        image = [path.dirname(url), maybeImage.image].join("/");
      } else {
        if (url.indexOf("pinata") === -1) {
          // TODO url is another ipfs resource
          const maybeMetadata = (await getCFIPFS(getUrlPath(url))).data;
          if (maybeMetadata.image.indexOf("ipfs") !== -1) {
            // use gateway url
            image = getCFIPFSUrl(getUrlPath(maybeMetadata.image));
          } else {
            // use url as is
            image = maybeMetadata.image;
          }
        } else {
          // pinata url contains image
          image = url;
        }
      }
    } else {
      // url may be gateway url
      image = url;
    }
  } catch (e) {
    // use placeholder as fallback
    image = placeholderImage;
  }
  return image;
};
