import { useAppSelector } from "hooks/useStore";
import { FC } from "react";
import { selectTheme } from "store/slices/app";

interface Props {
  name: string;
  alt?: string;
  width?: number;
  height?: number;
}

// Crypto icons can be found here: https://github.com/spothq/cryptocurrency-icons

const Icon: FC<Props> = ({ name, ...props }) => {
  const isDarkTheme = useAppSelector(selectTheme) === "dark";

  let source;
  try {
    source = require(`../../assets/images/icons/${name}${isDarkTheme ? "_white" : "_black"}.svg`);
  } catch (error) {
    console.log("Icon not found: ", name);
  }

  return <img className="icon" {...props} src={source} />;
};

export default Icon;
