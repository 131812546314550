import MyAlgoConnect from "@randlabs/myalgo-connect";
import WalletConnect from "@walletconnect/client";
import WalletConnectQRCodeModal from "@walletconnect/qrcode-modal";
import { WalletFallbackType } from "types";

export const getWalletMyAlgo = async () => {
  const connector = new MyAlgoConnect();
  return {
    provider: connector,
    connect: async () => {
      const accounts = await connector.connect();
      return accounts;
    },
    check: () => false,
    accounts: () => []
  };
};

export const getWalletWalletConect = async () => {
  const connector = new WalletConnect({
    bridge: "https://bridge.walletconnect.org",
    qrcodeModal: WalletConnectQRCodeModal
  });
  return {
    provider: connector,
    connect: async () => ((await connector.connect())?.accounts ?? []).map((address) => ({ address })),
    check: () => connector.connected,
    accounts: () =>
      connector.accounts.map((address) => ({
        address
      }))
  };
};

const getWallet = (walletFallback: WalletFallbackType) => {
  switch (walletFallback) {
    case "MyAlgoConnect":
      return getWalletMyAlgo();

    case "WalletConnect":
      return getWalletWalletConect();
    default:
      break;
  }
};

export const connectWallet = async (walletFallback: WalletFallbackType) => {
  const wallet = await getWallet(walletFallback);

  if (!wallet) return null;
  const accounts = wallet.check() ? wallet.accounts() : await wallet.connect();

  return accounts;
};
