import { useAppSelector } from "hooks/useStore";
import { useParams } from "react-router-dom";
import { selectAccounts, selectAssets } from "store/slices/auction";
import ContractLoader from "components/loaders/ContractLoader";
import { selectAddress, selectProviderType, selectReachAccount, selectWalletFallback } from "store/slices/app";
import { useReach } from "hooks/useReach";
import AuctionDetails from "components/AuctionDetails";
import * as backend from "services/utils/app/reverse/build/index.main.mjs";
import AuctionsList from "components/AuctionsList";

const AuctionPage = () => {
  const { id } = useParams();
  const walletFallback = useAppSelector(selectWalletFallback);
  const providerType = useAppSelector(selectProviderType);
  const reach = useReach(walletFallback, providerType);
  const reachAccount = useAppSelector(selectReachAccount);
  const accounts = useAppSelector(selectAccounts);
  const index = accounts.findIndex(({ appId }) => appId.toString() === id);
  const assets = useAppSelector(selectAssets);
  const address = useAppSelector(selectAddress);
  const creator = assets[index]?.params?.creator;

  return (
    <>
      <ContractLoader acc={reachAccount} appId={accounts[index].appId} stdlib={reach} backend={backend}>
        {(otherProps: any) => (
          <AuctionDetails
            acc={reachAccount}
            asset={assets[index]}
            account={accounts[index]}
            address={address}
            stdlib={reach}
            {...otherProps}
          />
        )}
      </ContractLoader>
      <AuctionsList creator={creator} assets={assets} accounts={accounts} />
    </>
  );
};

export default AuctionPage;
