import { gql } from "@apollo/client";

export const GET_ASSETS = gql`
  query getAssets($firstAssetId: Int, $limit: Int, $offset: Int, $orderBy: String) {
    getAssets(firstAssetId: $firstAssetId, limit: $limit, offset: $offset, orderBy: $orderBy) {
      id
      assetId
      assetInfo
      assetMetadata
      isDestroyed
      media
      collectionId
    }
  }
`;

export const GET_ASSET_BY_ID = gql`
  query getAssetById($assetId: Int!) {
    getAssetById(assetId: $assetId) {
      id
      assetId
      assetInfo
      assetMetadata
      isDestroyed
      media
      collectionId
    }
  }
`;

export const GET_COLLECTION_BY_ID = gql`
  query getCollectionById($collectionId: Int!) {
    getCollectionById(collectionId: $collectionId) {
      id
      name
      description
      assets {
        id
        assetId
        assetInfo
        assetMetadata
        isDestroyed
        media
      }
    }
  }
`;
