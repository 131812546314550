import { Stdlib_User } from "@reach-sh/stdlib/dist/types/interfaces";

/**
 * Collection of functions for formatCurrency custom utility.
 * will eventually be moved to stdlib
 */
function ldrop(str: string, char: string) {
  while (str[0] === char) {
    // eslint-disable-next-line no-param-reassign
    str = str.slice(1);
  }
  return str;
}

function rdrop(str: string, char: string) {
  while (str[str.length - 1] === char) {
    // eslint-disable-next-line no-param-reassign
    str = str.slice(0, str.length - 1);
  }
  return str;
}

function lpad(str: string, padChar: string, nChars: number) {
  const padding = padChar.repeat(Math.max(nChars - str.length, 0));
  return padding + str;
}

export const somethingFromSome =
  (f: (arg: any) => void, d: any) =>
  (some = []) =>
    some[1] ? f(some[1]) : d;

export const formatCurrencyFromSome2 = (stdlib: any, some: any, decimal = 4) =>
  somethingFromSome((some) => formatCurrency(stdlib)(some, decimal), 0)(some);

export const formatCurrency =
  (stdlib: any) =>
  (amt: any, decimals = 6): string => {
    if (!(Number.isInteger(decimals) && decimals >= 0)) {
      throw Error(`Expected decimals to be a nonnegative integer, but got ${decimals}.`);
    }
    const amtStr = stdlib.bigNumberify(amt).toString();
    const splitAt = Math.max(amtStr.length - decimals, 0);
    const lPredropped = amtStr.slice(0, splitAt);
    const l = ldrop(lPredropped, "0") || "0";
    if (decimals === 0) {
      return l;
    }
    const rPre = lpad(amtStr.slice(splitAt), "0", decimals);
    const rSliced = rPre.slice(0, decimals);
    const r = rdrop(rSliced, "0");

    return r ? `${l}.${r}` : l;
  };

// PARSE CURRENCY

/**
 *
 * @param {string|number} addr
 * @param {number} decimals (optional)
 * @returns big number to consumed by a reach program
 */
export const getAmtForContract = (stdlib: any) => (amount: any, decimals: number) => {
  const numericAmt: number = stdlib.isBigNumber(amount)
    ? amount.toNumber()
    : typeof amount === "string"
    ? parseFloat(amount)
    : typeof amount === "bigint"
    ? Number(amount)
    : amount;
  const conUnit = getConUnit(decimals);
  const value = numericAmt * conUnit;
  return stdlib.bigNumberify(Math.floor(value));
};

export const getConUnit = (decimals: number) => 10 ** decimals;

export const getAlgoView = async (reach: Stdlib_User<any, any, any, any, any, any, any, any, any, any>, ctc: any) => {
  const cp = somethingFromSome((a) => reach.formatCurrency(a, 10), 0)(await ctc.v.Auction.currentPrice());
  const fp = somethingFromSome((a) => reach.formatCurrency(a, 10), 0)(await ctc.v.Auction.floorPrice());
  const sp = somethingFromSome((a) => reach.formatCurrency(a, 10), 0)(await ctc.v.Auction.startPrice());
  const kp = somethingFromSome((a) => reach.formatCurrency(a, 10), 0)(await ctc.v.Auction.priceChangePerSec());
  const tok = somethingFromSome((a) => reach.bigNumberToNumber(a), 0)(await ctc.v.Auction.token());
  const endSecs: number = somethingFromSome((a) => reach.bigNumberToNumber(a), 0)(await ctc.v.Auction.endSecs());
  const closed = somethingFromSome((a) => a, false)(await ctc.v.Auction.closed());
  // derived
  const remainingSecs = ((now) => (endSecs > now ? endSecs - now : 0))(Math.floor(Date.now() / 1000));
  const displayCp = parseFloat(fp) + remainingSecs * parseFloat(kp);
  const view = {
    cp,
    fp,
    sp,
    tok,
    kp,
    endSecs,
    closed,
    remainingSecs,
    displayCp
  };

  return view;
};
