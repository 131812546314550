export default {
  TestNet: {
    LOXKESTUNTAAYX53P3VT3TWMFO2ZDSPORZGMZNAITSLUXGSG4N4UYCYQIE: {
      name: "The Evolution Project"
    },
    "7UBGYVIHJKBIDSVZABRZSGAMN55HZSBX4MK3VBCHVM6F7OIWSGEN3Z75L4": {
      name: "Skuli Frens"
    },
    X5EF4TYG2Q5EYAMG3LCVPW577HSTNIH6W7S3SKYDDOAKFOIKTDFF5ETCSU: {
      name: "Archirand"
    }
  },
  MainNet: {}
};
