import { Stdlib_User } from "@reach-sh/stdlib/dist/types/interfaces";
import { somethingFromSome } from "services/utils/reach";

export const getView =
  (reach: Stdlib_User<any, any, any, any, any, any, any, any, any, any>) =>
  async (ctc: {
    v: {
      Auction: {
        currentPrice: () => never[] | PromiseLike<never[] | undefined> | undefined;
        floorPrice: () => never[] | PromiseLike<never[] | undefined> | undefined;
        startPrice: () => never[] | PromiseLike<never[] | undefined> | undefined;
        priceChangePerSec: () => never[] | PromiseLike<never[] | undefined> | undefined;
        token: () => never[] | PromiseLike<never[] | undefined> | undefined;
        endSecs: () => never[] | PromiseLike<never[] | undefined> | undefined;
        closed: () => never[] | PromiseLike<never[] | undefined> | undefined;
        seller: () => never[] | PromiseLike<never[] | undefined> | undefined;
      };
    };
  }) => {
    const [mCp, mFp, mSp, mKp, mTok, mEnds, mClosed, mSeller] = await Promise.all([
      ctc.v.Auction.currentPrice(),
      ctc.v.Auction.floorPrice(),
      ctc.v.Auction.startPrice(),
      ctc.v.Auction.priceChangePerSec(),
      ctc.v.Auction.token(),
      ctc.v.Auction.endSecs(),
      ctc.v.Auction.closed(),
      ctc.v.Auction.seller()
    ]);
    const cp = somethingFromSome((a) => reach.formatCurrency(a, 10), 0)(mCp);
    const fp = somethingFromSome((a) => reach.formatCurrency(a, 10), 0)(mFp);
    const sp = somethingFromSome((a) => reach.formatCurrency(a, 10), 0)(mSp);
    const kp = somethingFromSome((a) => reach.formatCurrency(a, 10), 0)(mKp);
    const tok = somethingFromSome((a) => reach.bigNumberToNumber(a), 0)(mTok);
    const endSecs = somethingFromSome((a) => reach.bigNumberToNumber(a), 0)(mEnds);
    const closed = somethingFromSome((a) => a, "false")(mClosed) ? "true" : "false";
    const seller = somethingFromSome((a) => reach.formatAddress(a), "")(mSeller);
    // derived
    const remainingSecs = ((now) => (endSecs > now ? endSecs - now : 0))(Math.floor(Date.now() / 1000));
    const displayCp = parseFloat(fp) + remainingSecs * parseFloat(kp);
    const view = {
      cp,
      fp,
      sp,
      tok,
      kp,
      endSecs,
      closed,
      remainingSecs,
      displayCp,
      seller
    };

    return view;
  };
