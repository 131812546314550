import { FC, useEffect, useState } from "react";
import { Box, Button, Grid, Typography, Skeleton } from "@mui/material";
import { Account, Asset, AssetDetails } from "types";
import { useStyle } from "../../pages/Home/styles";
import Icon from "components/Icon";
import { getView } from "services/utils/app/reverse/functions";
import { Stdlib_User } from "@reach-sh/stdlib/dist/types/interfaces";
import { useAppSelector } from "hooks/useStore";
import { selectAddress, selectProviderType } from "store/slices/app";
import appService from "services/api/app";
import { getAssetInfo, resolveAssetImage } from "services/api/algorand";

interface Props {
  ctc?: any;
  appId?: number;
  acc: any;
  asset: AssetDetails;
  account: Account;
  stdlib: Stdlib_User<any, any, any, any, any, any, any, any, any, any>;
}

const Live: FC<Props> = ({ ctc, acc, asset, account, stdlib: reach }) => {
  const classes = useStyle();
  const address = useAppSelector(selectAddress);
  const providerType = useAppSelector(selectProviderType);
  const isAssetInWallet = acc?.assets?.find((el: Asset) => el["asset-id"] === asset?.index);

  const [view, setView] = useState<any>(null);
  const [assetInfo, setAssetInfo] = useState<any>(null);

  const onOptin = async () => {
    console.log("OPTIN");
    // -----------------------------------------------
    // optin
    // -----------------------------------------------
    await acc.tokenAccept(asset.index);
    // -----------------------------------------------
  };

  const onCancel = async () => {
    console.log("CANCEL");
    // -----------------------------------------------
    // cancel
    //   delete app
    // -----------------------------------------------
    await ctc.a.Bid.cancel();
    //await ctc.p.Relay({});
    appService.deleteApp(account.appId);
    // TODO refresh apps without reloading window
    window.location.reload();
    // -----------------------------------------------
  };

  const onBuyNow = async () => {
    console.log("BUY");
    // -----------------------------------------------
    // optin
    //   then touch
    //     then bid
    // -----------------------------------------------
    // REM optin, nop if opted in prior to buy now button click
    await acc.tokenAccept(asset.index);
    // sync onchain current price with display currenet price
    if (parseFloat(view.cp) > view.displayCp) {
      await ctc.a.Bid.touch(); //
      await ctc.a.Bid.touch(); //
    }
    await ctc.a.Bid.acceptOffer();
    appService.deleteApp(account.appId);
    window.location.reload();
    // -----------------------------------------------
  };

  // -------------------------------------------------
  // EFFECT: update view every block
  // -------------------------------------------------
  useEffect(() => {
    const timeout = setTimeout(
      () =>
        getView(reach)(ctc)
          .then((v) => {
            setView(v);
          })
          .catch(console.log),
      4000
    );
    return () => clearTimeout(timeout);
  }, [ctc, view]);
  // -------------------------------------------------
  // EFFECT: retrieve asset info
  // -------------------------------------------------
  useEffect(() => {
    console.log({ assetInfo, providerType });
    if (assetInfo) return;
    if (providerType === "TestNet") {
      console.log(asset);
      resolveAssetImage(asset).then((asset: any) =>
        setAssetInfo({
          media: {
            url: asset
          }
        })
      );
    } else {
      // TODO fix me later
      //getAssetInfo(String(asset.index)).then(setAssetInfo);
      getAssetInfo("325308062").then(setAssetInfo);
    }
  }, [asset, assetInfo]);
  // -------------------------------------------------
  return (
    <Box className={classes.top}>
      <Box className={classes.topLeft}>
        {view && assetInfo ? (
          <img src={assetInfo?.media?.url} alt={assetInfo.name} />
        ) : (
          <Skeleton variant="rectangular" width={160} height={160} />
        )}
      </Box>
      <Box className={classes.topRight}>
        <Typography variant="subtitle1" color="text.primary">
          Most recent auction
        </Typography>
        <Grid container spacing={2} alignItems="end">
          <Grid item>
            <Typography variant="h4" color="text.primary">
              {asset?.params?.name ?? "Unnamed"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <Icon name="algo" width={24} height={24} />
          </Grid>
          <Grid item>
            <Typography variant="body2" color="text.primary">
              {view && view.displayCp > 0 ? Number(view.displayCp).toFixed(2) : <Skeleton variant="text" width={50} />}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ textAlign: "right" }}>
          {view && view.displayCp > 0 ? (
            <>
              {address &&
                (!isAssetInWallet ? (
                  <Button variant="outlined" onClick={onOptin}>
                    Optin
                  </Button>
                ) : address === view.seller ? (
                  <Button variant="outlined" onClick={onCancel}>
                    Cancel
                  </Button>
                ) : (
                  <Button variant="outlined" onClick={onBuyNow}>
                    Buy Now
                  </Button>
                ))}
            </>
          ) : (
            <Skeleton
              style={{ display: "inline-block", borderRadius: "5px" }}
              variant="rectangular"
              width={101}
              height={39}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Live;
