import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import { selectAddress, selectProviderType, selectReachAccount, selectWalletFallback } from "store/slices/app";
import { Account, AssetDetails, ContractApp } from "types";
import { Box } from "@mui/material";
import { fetchAccounts, fetchApps, fetchAssets, selectAccounts, selectApps, selectAssets } from "store/slices/auction";
import ContractLoader from "components/loaders/ContractLoader";
import { useReach } from "hooks/useReach";
import { useStyle } from "./styles";
import Live from "components/Live";
import AuctionsList from "components/AuctionsList";
import CreatorList from "components/CreatorList";
import * as backend from "services/utils/app/reverse/build/index.main.mjs";

const Home: FC<{}> = () => {
  const classes = useStyle();
  const dispatch = useAppDispatch();
  const walletFallback = useAppSelector(selectWalletFallback);
  const providerType = useAppSelector(selectProviderType);
  const reach = useReach(walletFallback, providerType);
  const addr = useAppSelector(selectAddress);
  const reachAccount = useAppSelector(selectReachAccount);

  const apps: ContractApp[] = useAppSelector(selectApps);
  const accounts: Account[] = useAppSelector(selectAccounts);
  const assets: AssetDetails[] = useAppSelector(selectAssets);

  useEffect(() => {
    dispatch(fetchApps());
  }, [addr]);

  useEffect(() => {
    if (apps?.length) {
      dispatch(fetchAccounts(apps.map((app) => app.appId)));
    }
  }, [apps]);

  useEffect(() => {
    dispatch(fetchAssets(accounts));
  }, [accounts]);

  // TODO confirm that mri (below) is appropriate for accounts
  //      and assets for live component

  if (accounts.length == 0) return null;

  const mostRecentAccountIndex = Math.max(accounts.length - 1, 0);
  const mostRecentAssetId = accounts[mostRecentAccountIndex].assets?.[0]["asset-id"];
  const mostRecentAsset = assets.find((asst) => asst["index"] === mostRecentAssetId);

  return (
    <Box
      className={classes.root}
      sx={{
        bgcolor: "background.default"
      }}>
      {reachAccount
        ? !!accounts.length && (
            <>
              <ContractLoader
                acc={reachAccount}
                appId={accounts[mostRecentAccountIndex].appId}
                stdlib={reach}
                backend={backend}>
                {(otherProps: any) => (
                  <>
                    <hr />
                    <Live
                      acc={reachAccount}
                      asset={mostRecentAsset}
                      account={accounts[mostRecentAccountIndex]}
                      stdlib={reach}
                      {...otherProps}
                    />
                    <hr />
                  </>
                )}
              </ContractLoader>
              <CreatorList assets={assets} accounts={accounts.slice(0, -1)} />
            </>
          )
        : !!(accounts.length && assets.length) && <AuctionsList assets={assets} accounts={accounts} />}
    </Box>
  );
};

export default Home;
