import { FC } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useStyles } from "./styles";

interface Props {
  text?: string;
  iconSize?: number;
}

const LoadingIndicator: FC<Props> = ({ text, iconSize }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <CircularProgress size={iconSize} />
      {text && (
        <Typography variant="subtitle1" color="text.primary">
          {text}
        </Typography>
      )}
    </Box>
  );
};

export default LoadingIndicator;
