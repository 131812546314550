import { FC, useEffect, useState } from "react";
import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import Icon from "components/Icon";
import { useStyle } from "../../pages/Home/styles";
import { Account, Asset, AssetDetails } from "types";
import { Stdlib_User } from "@reach-sh/stdlib/dist/types/interfaces";
import { getView } from "services/utils/app/reverse/functions";
import appService from "services/api/app";
import { getAssetInfo, resolveAssetImage } from "services/api/algorand";
import { useAppSelector } from "hooks/useStore";
import { selectProviderType } from "store/slices/app";
//import { getAssetInfo } from "services/api/algorand";

interface Props {
  asset: AssetDetails;
  account: Account;
  ctc?: any;
  appId?: number;
  acc: any;
  address: string;
  stdlib: Stdlib_User<any, any, any, any, any, any, any, any, any, any>;
}

const AuctionDetails: FC<Props> = ({ address, asset, account, ctc, acc, stdlib: reach }) => {
  const classes = useStyle();
  const providerType = useAppSelector(selectProviderType);
  const [view, setView] = useState<any>(null);
  const [assetInfo, setAssetInfo] = useState<any>(null);

  const isAssetInWallet = acc?.assets?.find((el: Asset) => el["asset-id"] === asset.index);

  const onOptin = async () => {
    console.log("OPTIN");
    // -----------------------------------------------
    // optin
    // -----------------------------------------------
    await acc.tokenAccept(asset.index);
    // -----------------------------------------------
  };

  const onCancel = async () => {
    console.log("CANCEL");
    // -----------------------------------------------
    // cancel
    //   delete app
    // -----------------------------------------------
    await ctc.a.Bid.cancel();
    //await ctc.p.Relay({});
    appService.deleteApp(account.appId);
    // TODO refresh apps without reloading window
    window.location.reload();
    // -----------------------------------------------
  };

  const onBuyNow = async () => {
    console.log("BUY");
    // -----------------------------------------------
    // optin
    //   then touch
    //     then bid
    // -----------------------------------------------
    // REM optin, nop if opted in prior to buy now button click
    await acc.tokenAccept(asset.index);
    // sync onchain current price with display currenet price
    if (parseFloat(view.cp) > view.displayCp) {
      await ctc.a.Bid.touch(); //
      await ctc.a.Bid.touch(); //
    }
    await ctc.a.Bid.acceptOffer();
    appService.deleteApp(account.appId);
    // -----------------------------------------------
  };

  // -------------------------------------------------
  // EFFECT: update view
  // -------------------------------------------------
  useEffect(() => {
    getView(reach)(ctc)
      .then((v) => {
        setView(v);
      })
      .catch(console.log);
  }, [ctc, view, reach]);
  // -------------------------------------------------
  // EFFECT: retrieve asset info
  // -------------------------------------------------
  useEffect(() => {
    console.log({ assetInfo, providerType, asset });
    if (assetInfo) return;
    if (providerType === "TestNet") {
      console.log(asset);
      resolveAssetImage(asset).then((asset: any) =>
        setAssetInfo({
          media: {
            url: asset
          }
        })
      );
    } else {
      // TODO fix me later
      //getAssetInfo(String(asset.index)).then(setAssetInfo);
      getAssetInfo("325308062").then(setAssetInfo);
    }
  }, [asset, assetInfo]);
  // -------------------------------------------------

  if (!asset || !account || !assetInfo) return null;

  return (
    <Box className={classes.top}>
      <Box className={classes.topLeft}>
        <img src={assetInfo?.media?.url} alt={asset?.params?.name} />
      </Box>
      <Box className={classes.topRight}>
        <Typography variant="subtitle1" color="text.primary">
          Most recent auction
        </Typography>
        <Grid container spacing={2} alignItems="end">
          <Grid item>
            <Typography variant="h4" color="text.primary">
              {asset.params.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <Icon name="algo" width={24} height={24} />
          </Grid>
          <Grid item>
            <Typography variant="body2" color="text.primary">
              {view && view.displayCp > 0 ? Number(view.displayCp).toFixed(2) : <Skeleton variant="text" width={50} />}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ textAlign: "right" }}>
          {view && view.displayCp > 0 ? (
            <>
              {address &&
                (!isAssetInWallet ? (
                  <Button variant="outlined" onClick={onOptin}>
                    Optin
                  </Button>
                ) : address === view.seller ? (
                  <Button variant="outlined" onClick={onCancel}>
                    Cancel
                  </Button>
                ) : (
                  <Button variant="outlined" onClick={onBuyNow}>
                    Buy Now
                  </Button>
                ))}
            </>
          ) : (
            <Skeleton
              style={{ display: "inline-block", borderRadius: "5px" }}
              variant="rectangular"
              width={101}
              height={39}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AuctionDetails;
